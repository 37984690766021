import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { renderFormattedTime, renderFormattedDate, groupLogs, formatTimeAgo, groupGameLogsNCAAF, formatDateForTooltip } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, Grid, Button, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavTabs from 'components/NavTabs';
import ncaafRoutes from '../ncaafRoutes';
import SEO from 'components/SEO';
import seoConfig from '../../../config/seo.json';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ProtectedColumn } from 'components/ProtectedColumn';
import { Link } from 'react-router-dom';
import { withSeasonCheck } from '../../../config/withSeasonCheck';
import SportNavTabs from '../../../components/SportNavTabs';

const NCAAFtotal = () => {
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';

  useEffect(() => {
    fetch('/api/ncaaftotallog')
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(data => {
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const lastUpdateTimestamp = firstGroup && firstGroup[0] ? firstGroup[0].last_api_update : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };

  // Check if model data is available
  const hasModelData = logs.length > 0 && logs[0].model_status !== 'pending';

  return (
    <>
      <SEO {...seoConfig.ncaaf.total} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <div>
              <header>
                <h1>College Football Totals</h1>
                <p>Projected totals from <a href="https://x.com/ESPN_BillC" target="_blank" rel="noopener noreferrer">SP+</a>, the most respected model for college football predictions.</p>
                <p>Expected Value (EV) calculated by converting the sportsbook's total and comparing to the SP+ projected totals. Usually it's a percentage, for now it's the point differential. +3 means expected to go over by 3 points, -3 expects the under.</p>
                <p>Numbers in green suggest positive expected value from a betting perspective, these are +EV Bets.</p>
              </header>
              <Typography variant="body2" component="p" sx={{ fontStyle: 'italic' }}>
                Lines updated {lastUpdateTimestamp ? formatDateForTooltip(lastUpdateTimestamp) : 'unknown time'}. Updates every 2-4 hours, more frequent closer to game time.
              </Typography>
              {!hasModelData && logs.length > 0 && (
                <p><i><AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} /> Model predictions normally available on Tuesday ~7-8am EST. Odds data is displayed below.</i></p>
              )}
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: -0.25, mb: 2 }}>
              <SportNavTabs />
              <NavTabs routes={ncaafRoutes} />
            </Box>
            {logs.length === 0 ? (
              <div><b>Please check back soon, currently waiting on the next SP+ update. Usually happens on Tuesday, lines will be available soon after.</b></div>
            ) : (
              <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
                <Table sx={{ minWidth: 400}} aria-label="ncaaf-total-table">
                  <TableHead sx={{ bgcolor: 'grey.200' }}>
                    <TableRow>
                      <TableCell><strong>Game</strong></TableCell>
                      <TableCell {...headerStyling}><strong>SP+<br />Total</strong></TableCell>
                      <TableCell {...headerStyling}><strong>DraftKings<br />Total</strong></TableCell>
                      <TableCell {...headerStyling}><strong>DraftKings<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>FanDuel</strong></TableCell>
                      <TableCell {...headerStyling}><strong>FanDuel<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>Kickoff</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupGameLogsNCAAF(logs).map((gameLogs, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography variant={typographyVariant}>{gameLogs[0]?.away_team || gameLogs[1].away_team}</Typography>
                          <Typography variant={typographyVariant}>{gameLogs[0]?.home_team || gameLogs[1].home_team}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn feature="view_model_predictions">
                              <Typography variant={typographyVariant}>
                                {gameLogs[0]?.sp_total_score !== undefined ? Number(gameLogs[0]?.sp_total_score).toFixed(1) : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="Model predictions available Tuesday ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>
                            {gameLogs[0].totals_point !== undefined && gameLogs[0].totals_point !== '--' ? `o${gameLogs[0].totals_point}` : gameLogs[0].totals_point} 
                            {gameLogs[0].over_price !== '--' ? (gameLogs[0].over_price > 0 ? '+' : '') + gameLogs[0].over_price : gameLogs[0].over_price}
                          </Typography>
                          <Typography variant={typographyVariant}>
                            {gameLogs[0].totals_point !== undefined && gameLogs[0].totals_point !== '--' ? `u${gameLogs[0].totals_point}` : gameLogs[0].totals_point} 
                            {gameLogs[0].under_price !== '--' ? (gameLogs[0].under_price > 0 ? '+' : '') + gameLogs[0].under_price : gameLogs[0].under_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[0]?.point_diff >= 3 || gameLogs[0]?.point_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[0]?.point_diff >= 3 || gameLogs[0]?.point_diff < -3 ? 'bold' : 'normal' }}>
                                {gameLogs[0]?.point_diff !== undefined && gameLogs[0]?.point_diff !== '--' ? `${gameLogs[0].point_diff > 0 ? '+' : ''}${Number(gameLogs[0].point_diff).toFixed(1)}` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available Tuesday ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>
                            {gameLogs[1].totals_point !== undefined && gameLogs[1].totals_point !== '--' ? `o${gameLogs[1].totals_point}` : gameLogs[1].totals_point} 
                            {gameLogs[1].over_price !== '--' ? (gameLogs[1].over_price > 0 ? '+' : '') + gameLogs[1].over_price : gameLogs[1].over_price}
                          </Typography>
                          <Typography variant={typographyVariant}>
                            {gameLogs[1].totals_point !== undefined && gameLogs[1].totals_point !== '--' ? `u${gameLogs[1].totals_point}` : gameLogs[1].totals_point} 
                            {gameLogs[1].under_price !== '--' ? (gameLogs[1].under_price > 0 ? '+' : '') + gameLogs[1].under_price : gameLogs[1].under_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[1]?.point_diff >= 3 || gameLogs[1]?.point_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[1]?.point_diff >= 3 || gameLogs[1]?.point_diff < -3 ? 'bold' : 'normal' }}>
                                {gameLogs[1]?.point_diff !== undefined && gameLogs[1]?.point_diff !== '--' ? `${gameLogs[1].point_diff > 0 ? '+' : ''}${Number(gameLogs[1].point_diff).toFixed(1)}` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available Tuesday ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>{renderFormattedDate(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                          <Typography variant={typographyVariant}>{renderFormattedTime(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            
            {/* Related Content Section */}
            <Box sx={{ mt: 5, mb: 3, pt: 3, borderTop: `1px solid ${theme.palette.grey[200]}` }}>
              <Typography variant="h5" component="h3" sx={{ mb: 3, fontWeight: 700 }}>
                Continue Your NCAAF Betting Journey
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsFootballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NCAAF Betting Guide
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Comprehensive strategies, tips, and insights for betting on college football.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/ncaaf" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Guide
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsFootballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NCAAF Moneylines
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in college football moneyline markets with our model-based predictions.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/ncaaf/moneyline" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Moneylines
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsFootballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          NCAAF Spreads
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in college football point spread markets with our model-based predictions.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/ncaaf/spread" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Spreads
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Main>
      </Box>
    </>
  );
};

export default withSeasonCheck(NCAAFtotal, 'NCAAF');