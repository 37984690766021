import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from 'assets/logo.png';
// import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const { loginWithRedirect: login, isAuthenticated } = useAuth0();
  // const theme = useTheme();
  // const { mode } = theme.palette;

  return (
    <Box sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Logo and Company Section */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              component="a"
              href="/"
              title="+EV Bets"
              width={{ xs: 60, sm: 80 }}
            >
              <Box
                component={'img'}
                src={Logo}
                alt="+EV Bets"
                height={1}
                width={1}
              />
            </Box>
            <Typography variant="body2" color="text.secondary">
              Data-driven sports betting analytics to help you make more informed decisions.
            </Typography>
          </Box>
        </Grid>

        {/* Resources Section */}
        <Grid item xs={6} sm={3} md={2}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1" fontWeight={600}>
              Resources
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Link href="/faq" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                FAQ
              </Link>
              <Link href="/about" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                About
              </Link>
              <Link href="https://www.linkedin.com/in/pfhayes/" target="_blank" rel="noopener noreferrer" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Contact
              </Link>
              {/* <Link href="/glossary" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Betting Glossary
              </Link>
              <Link href="/how-it-works" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                How It Works
              </Link>
              <Link href="/support" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Support
              </Link> */}
            </Box>
          </Box>
        </Grid>

        {/* Legal Section */}
        <Grid item xs={6} sm={3} md={2}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1" fontWeight={600}>
              Legal
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              <Link href="/terms" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Terms of Service
              </Link>
              <Link href="/privacy" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Privacy Policy
              </Link>
              <Link href="/responsible-gambling" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Responsible Gambling
              </Link>
            </Box>
          </Box>
        </Grid>

        {/* Sports Section */}
        <Grid item xs={6} sm={3} md={2}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1" fontWeight={600}>
              Sports
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {/* Most Popular */}
              <Link href="/nfl" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                NFL
              </Link>
              <Link href="/nba" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                NBA
              </Link>
              <Link href="/ncaaf" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                NCAA Football
              </Link>
              <Link href="/mens-cbb" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                NCAA Basketball
              </Link>
              {/* Secondary */}
              <Link href="/mlb" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                MLB
              </Link>
              <Link href="/mlb/props" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                MLB Props
              </Link>
              <Link href="/nhl" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                NHL
              </Link>
            </Box>
          </Box>
        </Grid>

        {/* Community Section */}
        <Grid item xs={6} sm={3} md={2}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1" fontWeight={600}>
              Community
            </Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {/* <Link href="/blog" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                Blog 
              </Link> */}
              <Link href="https://x.com/PlusEV_Bets" target="_blank" rel="noopener noreferrer" underline="none" color="text.secondary" sx={{ '&:hover': { color: 'primary.main' } }}>
                X
              </Link>
            </Box>
          </Box>
        </Grid>

        {/* Bottom Section */}
        <Grid item xs={12}>
          <Box 
            sx={{ 
              borderTop: 1, 
              borderColor: 'divider',
              pt: 2,
              mt: 2,
              // display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography
              align={'center'}
              variant={'caption'}
              color="text.secondary"
              component={'p'}
              sx={{ mt: 2 }}
            >
              &copy; +EV Bets {new Date().getFullYear()}. All rights reserved
              <br />
              Hail to the Victors.
            </Typography>
          </Box>
          
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
