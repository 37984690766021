import React from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Button, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportNavTabs from '../../../components/SportNavTabs';
import NavTabs from 'components/NavTabs';
import mlbRoutes from '../mlbRoutes';
import NewsletterWithCard from '../../IndexPage/components/NewsletterWithCard/NewsletterWithCard';

// This component is NOT wrapped with withSeasonCheck so it's always visible
const MLBPropPage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Main>
      <Helmet>
        <title>MLB Player Props: Strikeout Props & Betting Analysis | +EV Bets</title>
        <meta name="description" content="Expert MLB player props analysis, including pitcher strikeout predictions and prop betting strategies. Find the best value in MLB player props with our data-driven approach." />
        <meta name="keywords" content="MLB props, baseball props, MLB strikeout props, pitcher strikeout predictions, MLB player props, MLB prop betting" />
        <link rel="canonical" href="https://plusevbets.com/mlb/props" />
        <meta property="og:title" content="MLB Player Props: Strikeout Props & Betting Analysis | +EV Bets" />
        <meta property="og:description" content="Expert MLB player props analysis, including pitcher strikeout predictions and prop betting strategies. Find the best value in MLB player props with our data-driven approach." />
        <meta property="og:url" content="https://plusevbets.com/mlb/props" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsEvent",
              "name": "MLB Player Props",
              "description": "MLB player props betting analysis and predictions",
              "url": "https://plusevbets.com/mlb/props",
              "sport": "Baseball"
            }
          `}
        </script>
      </Helmet>

      {/* Hero Section */}
      <Box 
        sx={{
          background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
          position: 'relative',
          overflow: 'hidden',
          py: { xs: 6, md: 10 },
          mb: { xs: 4, md: 6 }
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box>
                <Typography 
                  variant="h1" 
                  component="h1" 
                  sx={{ 
                    fontSize: { xs: '2.5rem', md: '3.5rem' }, 
                    fontWeight: 800, 
                    color: 'white',
                    mb: 2,
                    lineHeight: 1.2
                  }}
                >
                  MLB Player Props Analysis
                </Typography>
                <Typography 
                  variant="h2" 
                  component="h2" 
                  sx={{ 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }, 
                    fontWeight: 400, 
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 4,
                    maxWidth: '90%'
                  }}
                >
                  Find value in MLB player props with our advanced statistical models and predictions
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Button 
                    component={Link} 
                    to="/mlb/props/starting-pitcher-strikeout-over-unders" 
                    variant="contained" 
                    size="large"
                    sx={{ 
                      px: 3, 
                      py: 1.5,
                      fontWeight: 600,
                      fontSize: '1rem',
                      bgcolor: 'white',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    Pitcher Strikeout Props
                  </Button>
                </Box>
              </Box>
            </Grid>
            {isMd && (
              <Grid item md={5}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box 
                    sx={{
                      width: 300,
                      height: 300,
                      borderRadius: '50%',
                      background: 'rgba(255, 255, 255, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <SportsBaseballIcon 
                      sx={{ 
                        fontSize: 180, 
                        color: 'rgba(255, 255, 255, 0.8)'
                      }} 
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '15%',
            minHeight: 50
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 8 }}>
        {/* Main Content Grid */}
        <Grid container spacing={4}>
          {/* Strikeout Props Card */}
          <Grid item xs={12} md={6}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%', 
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <SportsBaseballIcon sx={{ color: theme.palette.primary.main, mr: 1.5, fontSize: '2rem' }} />
                  <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                    Starting Pitcher Strikeouts
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, flexGrow: 1 }}>
                  Compare starting pitcher strikeout props across sportsbooks and find the best over/under opportunities. Our xK model predicts strikeout totals using advanced metrics and machine learning.
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Features:
                  <ul>
                    <li>Proprietary xK predictions</li>
                    <li>Expected Value (EV) calculations</li>
                    <li>DraftKings & FanDuel odds comparison</li>
                    <li>Regular updates throughout the day</li>
                  </ul>
                </Typography>
                <Button 
                  component={Link} 
                  to="/mlb/props/starting-pitcher-strikeout-over-unders" 
                  variant="contained" 
                  color="primary" 
                  size="large"
                  fullWidth
                >
                  View Strikeout Props
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Coming Soon Card */}
          <Grid item xs={12} md={6}>
            <Card 
              variant="outlined" 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'action.hover',
              }}
            >
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <SportsBaseballIcon sx={{ color: theme.palette.text.secondary, mr: 1.5, fontSize: '2rem' }} />
                  <Typography variant="h5" component="h2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
                    More Props Coming Soon
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, flexGrow: 1, color: 'text.secondary' }}>
                  We're working on expanding our MLB props coverage. Stay tuned for:
                  <ul>
                    <li>Starting Pitcher Outs</li>
                    <li>Starting Pitcher Hits Allowed</li>
                    <li>Player Home Runs</li>
                    <li>Player Total Bases</li>
                  </ul>
                </Typography>
                <Button 
                  variant="outlined" 
                  color="inherit" 
                  size="large"
                  fullWidth
                  disabled
                >
                  Coming Soon
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Strategy Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h3" sx={{ mb: 4, fontWeight: 700 }}>
            MLB Player Props Betting Strategy
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                Understanding Expected Value (EV)
              </Typography>
              <Typography variant="body1" paragraph>
                Expected Value (EV) is crucial in props betting. Our models calculate EV by comparing sportsbook odds with our predicted probabilities. When our model identifies significant discrepancies, it highlights potential value opportunities.
              </Typography>
              <Typography variant="body1" paragraph>
                For pitcher strikeout props, we consider:
                <ul>
                  <li>Pitcher's recent performance and historical K/9 rates</li>
                  <li>Opposing team's strikeout tendencies and lineup changes</li>
                  <li>Weather conditions and ballpark factors</li>
                  <li>Umpire tendencies and strike zone patterns</li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                Key Factors in Props Analysis
              </Typography>
              <Typography variant="body1" paragraph>
                Successful props betting requires a comprehensive analysis of multiple factors:
                <ul>
                  <li>Pitcher matchup history and recent form</li>
                  <li>Team rest days and bullpen usage</li>
                  <li>Line movement and market trends</li>
                  <li>Starting lineup composition</li>
                  <li>Weather impact on pitch movement</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Tips Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h3" sx={{ mb: 4, fontWeight: 700 }}>
            MLB Props Betting Tips
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Line Shopping
                  </Typography>
                  <Typography variant="body1">
                    Always compare odds across multiple sportsbooks. Even small differences in strikeout totals or odds can significantly impact your long-term profitability. We track odds from major sportsbooks to help you find the best lines.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Bankroll Management
                  </Typography>
                  <Typography variant="body1">
                    Maintain consistent bet sizing and avoid chasing losses. We recommend betting between 1-3% of your bankroll on any single prop, regardless of how confident you feel about the prediction.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Weather Impact
                  </Typography>
                  <Typography variant="body1">
                    Pay attention to weather conditions, especially wind. Strong winds can affect pitch movement and carry, potentially impacting strikeout totals. Our model incorporates weather data into its predictions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Newsletter CTA Section */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <NewsletterWithCard />
        </Box>
      </Container>
    </Main>
  );
};

export default MLBPropPage; 