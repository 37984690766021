import StartingPitcherStrikeout from './StartingPitcherStrikeout';

const mlbPropsRoutes = [
  {
    path: '/mlb/props/starting-pitcher-strikeout-over-unders',
    label: 'SP Ks O/U',
    renderer: (params = {}) => <StartingPitcherStrikeout {...params} />
  }
  // Add more prop routes here as they become available
];

export default mlbPropsRoutes; 