import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import Main from 'layouts/Main';
import SEO from 'components/SEO';
import seoConfig from '../../../config/seo.json';
import { renderFormattedTime, renderFormattedDate, groupLogs, groupGameLogsNCAAF, formatTimeAgo, formatDateForTooltip } from 'utils/utils';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Card, CardContent, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavTabs from 'components/NavTabs';
import mensCBBRoutes from '../mensCBBRoutes';
import { useApi } from 'services/api';
import { ProtectedColumn } from 'components/ProtectedColumn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { Link } from 'react-router-dom';
import { withSeasonCheck } from '../../../config/withSeasonCheck';
import SportNavTabs from '../../../components/SportNavTabs';

const MensCBBspread = () => {
  const { callApi } = useApi();
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const typographyVariant = isMd ? 'body2' : 'subtitle1';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await callApi('/api/menscbbspreadlog');
        data.sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
        setLogs(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [callApi]);

  const groupedLogs = groupLogs(logs);
  const firstGroup = groupedLogs[0];
  // const firstBookmaker = firstGroup && firstGroup[0] ? firstGroup[0].bookmaker : null;
  // const secondGroup = groupedLogs[1];
  // const secondBookmaker = secondGroup && secondGroup[1] ? secondGroup[1].bookmaker : null;
  const timeSinceUpdate = firstGroup && firstGroup[0] ? formatTimeAgo(firstGroup[0].last_api_update) : null;
  const lastUpdateTimestamp = firstGroup && firstGroup[0] ? firstGroup[0].last_api_update : null;
  const headerStyling = { align: 'right', style: { verticalAlign: 'top' } };

  // Check if model data is available
  const hasModelData = logs.length > 0 && logs[0].model_status !== 'pending';

  return (
    <>
      <SEO {...seoConfig.mensCBB.spread} />
      <Box sx={{ overflowX: 'hidden' }}>
        <Main bgcolor={'background.paper'}>
          <Container>
            <div>
              <header>
                <h1>NCAA Mens Basketball Spreads</h1>
                <p>Projected spreads from <a href="https://barttorvik.com/" target="_blank" rel="noopener noreferrer">Bart Torvik Ratings</a> (Torvik), one of the most respected models for mens college basketball predictions.</p>
                <p>Expected Value (EV) calculated by converting the sportsbook's spread and comparing to the Torvik projected spreads. Usually it's a percentage, for now it's the point differntial. +3 means there are three points of value in the bet.</p>
                <p>Numbers in green suggest positive expected value from a betting perspective, these are +EV Bets.</p>
              </header>
              <Typography variant="body2" component="p" sx={{ fontStyle: 'italic' }}>
                Lines updated {lastUpdateTimestamp ? formatDateForTooltip(lastUpdateTimestamp) : 'unknown time'}. Updates every 2-4 hours, more frequent closer to game time.
              </Typography>
              {!hasModelData && logs.length > 0 && (
                <p><i><AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} /> Model predictions normally available after ~7-8am EST. Odds data is displayed below.</i></p>
              )}
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: -0.25, mb: 2 }}>
              <SportNavTabs />
              <NavTabs routes={mensCBBRoutes} />
            </Box>
            {logs.length === 0 ? (
              <div><b>Please check back soon, currently waiting on the next Torvik update. Lines will be avialable soon after.</b></div>
            ) : (
              <TableContainer component={Paper} sx={{ mx: 'auto', maxWidth: 950 }}>
                <Table sx={{ minWidth: 400}} aria-label="nfl-spread-table">
                  <TableHead sx={{ bgcolor: 'grey.200' }}>
                    <TableRow>
                      <TableCell><strong>Game</strong></TableCell>
                      <TableCell {...headerStyling}><strong>Torvik<br />Spread</strong></TableCell>
                      <TableCell {...headerStyling}><strong>DraftKings</strong></TableCell>
                      <TableCell {...headerStyling}><strong>DraftKings<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>FanDuel</strong></TableCell>
                      <TableCell {...headerStyling}><strong>FanDuel<br />EV</strong></TableCell>
                      <TableCell {...headerStyling}><strong>Tipoff</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupGameLogsNCAAF(logs).map((gameLogs, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography variant={typographyVariant}>
                            {gameLogs[0]?.away_team || gameLogs[0]?.the_away_team || gameLogs[1]?.away_team || gameLogs[1]?.the_away_team}
                          </Typography>
                          <Typography variant={typographyVariant}>
                            {gameLogs[0]?.home_team || gameLogs[0]?.the_home_team || gameLogs[1]?.home_team || gameLogs[1]?.the_home_team}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn feature="view_model_predictions">
                              <Typography variant={typographyVariant}>{gameLogs[0].torvik_away_team_spread > 0 ? '+' : ''}{gameLogs[0].torvik_away_team_spread || gameLogs[1].torvik_away_team_spread > 0 ? '' : ''}{gameLogs[1].torvik_away_team_spread}</Typography>
                              <Typography variant={typographyVariant}>{gameLogs[0].torvik_home_team_spread > 0 ? '+' : ''}{gameLogs[0].torvik_home_team_spread || gameLogs[1].torvik_home_team_spread > 0 ? '' : ''}{gameLogs[1].torvik_home_team_spread}</Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="Model predictions available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>
                            {gameLogs[0].away_team_spread !== undefined && gameLogs[0].away_team_spread !== '--' ? `${gameLogs[0].away_team_spread > 0 ? '+' : ''}${gameLogs[0].away_team_spread} ` : gameLogs[0].away_team_spread}
                            {gameLogs[0].away_team_price !== '--' ? (gameLogs[0].away_team_price > 0 ? '+' : '') + gameLogs[0].away_team_price : gameLogs[0].away_team_price}
                          </Typography>
                          <Typography variant={typographyVariant}>
                            {gameLogs[0].home_team_spread !== undefined && gameLogs[0].home_team_spread !== '--' ? `${gameLogs[0].home_team_spread > 0 ? '+' : ''}${gameLogs[0].home_team_spread} ` : gameLogs[0].home_team_spread}
                            {gameLogs[0].home_team_price !== '--' ? (gameLogs[0].home_team_price > 0 ? '+' : '') + gameLogs[0].home_team_price : gameLogs[0].home_team_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[0]?.away_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[0]?.away_diff < -3 ? 'bold' : 'normal' }} >
                                {gameLogs[0]?.away_diff !== undefined && gameLogs[0]?.away_diff !== '--' ? `${gameLogs[0].away_diff < 0 ? '+' : '-'}${Math.abs(gameLogs[0].away_diff)}` : '--'}
                              </Typography>
                              <Typography variant={typographyVariant} style={{ color: gameLogs[0]?.home_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[0]?.home_diff < -3 ? 'bold' : 'normal' }} >
                                {gameLogs[0]?.home_diff !== undefined && gameLogs[0]?.home_diff !== '--' ? `${gameLogs[0].home_diff < 0 ? '+' : '-'}${Math.abs(gameLogs[0].home_diff)}` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>
                            {gameLogs[1].away_team_spread !== undefined && gameLogs[1].away_team_spread !== '--' ? `${gameLogs[1].away_team_spread > 0 ? '+' : ''}${gameLogs[1].away_team_spread} ` : gameLogs[1].away_team_spread}
                            {gameLogs[1].away_team_price !== '--' ? (gameLogs[1].away_team_price > 0 ? '+' : '') + gameLogs[1].away_team_price : gameLogs[1].away_team_price}
                          </Typography>
                          <Typography variant={typographyVariant}>
                            {gameLogs[1].home_team_spread !== undefined && gameLogs[1].home_team_spread !== '--' ? `${gameLogs[1].home_team_spread > 0 ? '+' : ''}${gameLogs[1].home_team_spread} ` : gameLogs[1].home_team_spread}
                            {gameLogs[1].home_team_price !== '--' ? (gameLogs[1].home_team_price > 0 ? '+' : '') + gameLogs[1].home_team_price : gameLogs[1].home_team_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {hasModelData ? (
                            <ProtectedColumn
                              feature="view_ev_data"
                              tooltipText="Upgrade to see EV calculations"
                            >
                              <Typography variant={typographyVariant} style={{ color: gameLogs[1]?.away_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[1]?.away_diff < -3 ? 'bold' : 'normal' }} >
                                {gameLogs[1]?.away_diff !== undefined && gameLogs[1]?.away_diff !== '--' ? `${gameLogs[1].away_diff < 0 ? '+' : '-'}${Math.abs(gameLogs[1].away_diff)}` : '--'}
                              </Typography>
                              <Typography variant={typographyVariant} style={{ color: gameLogs[1]?.home_diff < -3 ? 'green' : 'inherit', fontWeight: gameLogs[1]?.home_diff < -3 ? 'bold' : 'normal' }} >
                                {gameLogs[1]?.home_diff !== undefined && gameLogs[1]?.home_diff !== '--' ? `${gameLogs[1].home_diff < 0 ? '+' : '-'}${Math.abs(gameLogs[1].home_diff)}` : '--'}
                              </Typography>
                            </ProtectedColumn>
                          ) : (
                            <Tooltip title="EV calculations for members available ~7-8am EST">
                              <AccessTimeIcon color="action" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant={typographyVariant}>{renderFormattedDate(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                          <Typography variant={typographyVariant}>{renderFormattedTime(gameLogs[0].commence_time || gameLogs[1].commence_time)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            
            {/* Related Content Section */}
            <Box sx={{ mt: 5, mb: 3, pt: 3, borderTop: `1px solid ${theme.palette.grey[200]}` }}>
              <Typography variant="h5" component="h3" sx={{ mb: 3, fontWeight: 700 }}>
                Continue Your College Basketball Betting Journey
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsBasketballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          CBB Betting Guide
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Comprehensive strategies, tips, and insights for betting on college basketball.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/mens-cbb" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Guide
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsBasketballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          CBB Moneylines
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Find value in college basketball moneyline markets with our model-based predictions.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/mens-cbb/moneyline" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Moneylines
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card variant="outlined" sx={{ height: '100%', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' } }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                        <SportsBasketballIcon sx={{ color: theme.palette.primary.main, mr: 1.5 }} />
                        <Typography variant="h6" component="h4" sx={{ fontWeight: 600 }}>
                          CBB Totals
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Compare college basketball over/under odds and find the best totals betting opportunities.
                      </Typography>
                      <Button 
                        component={Link} 
                        to="/mens-cbb/total" 
                        variant="outlined" 
                        color="primary" 
                        size="small"
                        fullWidth
                      >
                        View Totals
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Main>
      </Box>
    </>
  );
};

export default withSeasonCheck(MensCBBspread, 'MENSCBB');
