import MLBmoneyline from './MLBmoneyline/MLBmoneyline';
import MLBspread from './MLBspread/MLBspread';
import MLBtotal from './MLBtotal/MLBtotal';
import StartingPitcherStrikeout from './MLBprops/StartingPitcherStrikeout';

const mlbRoutes = [
  {
    path: '/mlb/moneyline',
    label: 'Moneylines',
    renderer: (params = {}) => <MLBmoneyline {...params} />
  },
  {
    path: '/mlb/spread',
    label: 'Spreads',
    renderer: (params = {}) => <MLBspread {...params} />
  },
  {
    path: '/mlb/total',
    label: 'Totals',
    renderer: (params = {}) => <MLBtotal {...params} />
  },
  {
    path: '/mlb/props/starting-pitcher-strikeout-over-unders',
    label: 'Props',
    renderer: (params = {}) => <StartingPitcherStrikeout {...params} />
  }
];

export default mlbRoutes; 